.red--text {
  color: red !important;
}

.green--text {
  color: green !important;
}

.text--strong {
  font-weight: 600;
}

body {
  overflow-y: auto !important;
  width: 100% !important;
}

header#layoutHeader {
  position: fixed;
  width: -webkit-fill-available;
}

thead.ant-table-thead {
  th {
    white-space: pre-wrap;
  }
}

#root > section > section > aside > div {
  position: fixed;
  width: inherit;
  overflow-y: auto;
}
.ant-tag {
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
}

.ant-layout {
  background-color: #f0f0f0 !important;
}

.ant-layout-sider-children {
  overflow-x: hidden;
}

.tox-notifications-container {
  display: none;
}

.ant-form-item-label {
  padding: 0px !important;
}

.text--strong {
  font-weight: 600 !important;
}

.text--center {
  text-align: center !important;
}

.m-0 {
  margin: 0 !important;
}

.w--full {
  width: 100% !important;
}

.w--120 {
  width: 120px !important;
}

.mt-12 {
  margin-top: 12px;
}

.text--600 {
  font-weight: 600;
}

body {
  font-family: HarmonyOS Sans,PingFang TC,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,Helvetica,Arial,Hiragino Sans GB,WenQuanYi Micro Hei,sans-serif;
}
@font-size-base: 14px;